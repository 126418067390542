// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kitchen-sink-tsx": () => import("./../../../src/pages/kitchen-sink.tsx" /* webpackChunkName: "component---src-pages-kitchen-sink-tsx" */),
  "component---src-pages-our-approach-tsx": () => import("./../../../src/pages/our-approach.tsx" /* webpackChunkName: "component---src-pages-our-approach-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-tour-ci-cd-tsx": () => import("./../../../src/pages/tour/ci-cd.tsx" /* webpackChunkName: "component---src-pages-tour-ci-cd-tsx" */),
  "component---src-pages-tour-custom-code-tsx": () => import("./../../../src/pages/tour/custom-code.tsx" /* webpackChunkName: "component---src-pages-tour-custom-code-tsx" */),
  "component---src-pages-tour-developers-tsx": () => import("./../../../src/pages/tour/developers.tsx" /* webpackChunkName: "component---src-pages-tour-developers-tsx" */),
  "component---src-pages-tour-index-tsx": () => import("./../../../src/pages/tour/index.tsx" /* webpackChunkName: "component---src-pages-tour-index-tsx" */),
  "component---src-pages-tour-users-tsx": () => import("./../../../src/pages/tour/users.tsx" /* webpackChunkName: "component---src-pages-tour-users-tsx" */)
}

